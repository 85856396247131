/* The checkbox-container */
.checkbox-container {
  display: flex;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: fit-content;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1.75px;
  left: 1.75px;
  height: 20px;
  width: 20px;
  border-radius: 16px;
  transition-duration: 300ms;
  border: 2px solid rgb(148 140 242);
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  border: 2px solid rgba(149, 140, 242, 0.9);
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: rgb(148 140 242);
}
