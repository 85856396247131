/* proxies.css */

.proxies-table {
  width: 100%;
  height: 79dvh;
}

.proxies-table-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-collapse: collapse;
  font-size: 14px;
}

.proxies-table-wrapper {
  width: 100%;
  margin-bottom: 24px;
}

.proxies-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  margin-bottom: 10px;
}

.group-type-title {
  font-size: 18px;
  color: #3f3f3f;
}

.filter-container {
  display: flex;
  align-items: center;
}

.reset-filter-btn {
  background-color: transparent;
  border: none;
  color: #f25757;
  padding: 5px 10px;
  cursor: pointer;
  width: 60px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 4px;
}

.filter-dropdown-wrapper {
  display: flex;
  align-items: center;
}

.filter-selection-wrapper {
  display: flex;
}

.filter-clear-visible {
  display: inline;
  padding: 0;
  background-color: transparent;
  border: none;
  color: #f25757;
  height: 12px;
  width: 12px;
  cursor: pointer;
}

.filter-clear-invisible {
  display: none;
}

.filter-selection {
  background-color: #8cdfd6;
  border: none;
  padding: 5px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.filter-text {
  background-color: #8cdfd6;
  border: none;
  color: black;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.proxies-table-row {
  background-color: #f2f2f2;
  margin-bottom: 10px;
  padding: 12px 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  font-size: 14px;
}
.proxies-table-row * {
  color: #3f3f3f;
}

.proxies-table-row:hover {
  background-color: #e5e2ff; /* Highlight color on hover */
}

.proxies-row-formtype {
  flex: 0 0 150px;
  position: relative;
  text-align: center;
  z-index: 1;
}

.proxies-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.proxies-quarter {
  flex: 1 0 auto;
  text-align: left; /* Align text to the left */
  padding-left: 10px; /* Adjust the padding to fine-tune the position */
}

.proxies-date {
  flex: 0 0 150px;
  text-align: right;
  padding-right: 10px; /* Adjust the padding to fine-tune the position */
}

.proxies-no-data {
  text-align: center;
  width: 100%;
  align-items: center;
  color: #8cdfd6;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.proxies-load-more {
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 12px 16px;
}

.proxies-load-more-btn {
  background-color: transparent;
  border: none;
  color: #3f3f3f;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.proxies-load-more-btn.loading {
  cursor: wait;
}
