.summary-content * {
  font-size: 14px;
}
.summary-content .heading {
  @apply font-primary text-[18px] font-semibold;
}
.summary-content table {
  margin-top: 0;
  border-spacing: 0 8px;
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 16px;
  margin: 12px 0;
}
.summary-content td {
  background-color: transparent !important;
}
.summary-content table * {
  font-size: 12px;
}
.document-options {
  background-color: #3b3aa0;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 60px;
  font-size: x-large;
  color: #8cdfd6;
  padding-left: 60px;
  margin-right: 20px;
  border-radius: 11px;
}
.document-options svg {
  cursor: pointer;
}
.documents-container {
  height: 62.75dvh;
  width: 100%;
  display: grid;
  display: grid;
  align-items: center;
  grid-template-columns: 6fr 4fr;
  gap: 20px;
  grid-template-columns: 6fr 4fr;
  gap: 20px;
  margin-right: 20px;
  overflow-x: hidden;
  overflow: hidden;
}
.document {
  background-color: white;
  height: 100%;
  color: black;
  text-align: start;
  border-radius: 11px;
  font-size: 24px;
  width: 100%;
  overflow: hidden;
  width: 100%;
  overflow: hidden;
}
.document-original {
  width: 100%;
  height: 100%;
  border: none;
}
.document-summarized {
  overflow: auto !important;
  box-sizing: border-box;
  padding: 20px;
  height: 100%;
}
.document-summarized-subhead {
  color: #3b3aa0;
  margin: 0;
}
.documents-sidebar,
.financials-sidebar {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  align-items: center;
}
@media (max-width: 768px) {
  .documents-container {
    display: flex;
    display: flex;
    flex-direction: column;
    width: auto;
    margin-right: 0;
    padding: 20px;
    height: 80dvh;
    margin-bottom: 100px;
    width: auto;
    margin-right: 0;
    padding: 20px;
    height: 80dvh;
    margin-bottom: 100px;
  }
  .document {
    width: 100%;
    height: 50%;
  }
  .documents-sidebar,
  .financials-sidebar {
    flex-direction: row;
    padding: 5px 10px;
  }
}
