.insider-container {
  width: 100%;
  height: 79dvh;
}

.insider-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.insider-title {
  font-size: 15px;
  color: #3f3f3f;
}

.insider-table-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-collapse: collapse;
  font-size: 14px;
}

.insider-table-wrapper {
  width: 100%;
  margin-bottom: 24px;
}

.insider-table-titles,
.insider-row {
  display: flex;
  align-items: center;
  padding: 10px 16px; /* Ensure consistent padding */
}

.insider-title,
.insider-cell {
  flex: 1; /* Make sure both titles and cells take equal space */
  text-align: left; /* Align text to the left */
  padding: 10px 16px; /* Consistent padding across titles and cells */
  white-space: nowrap; /* Prevent text wrapping */
}

/* Specific widths for certain columns */
.insider-title.name,
.insider-cell.name {
  font-weight: 600;
  text-align: center;
  flex: 2; /* Double the space for the 'Name' column */
}

.insider-title.transaction-type,
.insider-title.price-per-share,
.insider-title.shares,
.insider-title.value,
.insider-title.date,
.insider-title.tenb5-1,
.insider-cell.transaction-type,
.insider-cell.price-per-share,
.insider-cell.shares,
.insider-cell.value,
.insider-cell.date,
.insider-cell.tenb5-1 {
  flex: 1;
  font-weight: 600;
  text-align: center; /* Center align for better readability */
}

.insider-title.expand-column,
.insider-cell.expand-column {
  flex: 0.1; /* Smaller space for the expand column */
  text-align: center; /* Center the expand icon */
}

/* Ensure hover effects apply only to the table rows */
.insider-table-row {
  background-color: #f2f2f2;
  margin-bottom: 10px;
  padding: 12px 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  font-size: 14px;
}

.insider-table-row:hover,
.insider-table-row.nested-row:hover {
  background-color: #e5e2ff;
}

/* Flexbox for aligning row content */
.insider-row {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}

.insider-name {
  flex: 2;
}

.insider-transactionType,
.insider-pricePerShare,
.insider-shares,
.insider-value,
.insider-date,
.insider-10b5-1 {
  flex: 1;
  text-align: center;
}

/* Classes for different transaction types */
.Grant-or-Award {
  background-color: #d4edda;
}
.Conversion {
  background-color: #f8d7da;
}
.Disposition {
  background-color: #fff3cd;
}
.Expiration {
  background-color: #d1ecf1;
}
.Tax-Payment {
  background-color: #cce5ff;
}
.Bona-Fide-Gift {
  background-color: #e2e3e5;
}
.Cancellation-of-Derivative {
  background-color: #f5c6cb;
}
.Discretionary-Transaction {
  background-color: #c3e6cb;
}
.Other-Acquisition {
  background-color: #bee5eb;
}
.Small-Acquisition {
  background-color: #ffeeba;
}
.Derivative-Exercise {
  background-color: #d4edda;
}
.Out-of-money-Derivative {
  background-color: #f8d7da;
}
.Open-Market-Purchase {
  background-color: #c3e6cb;
}
.Open-Market-Sale {
  background-color: #d1ecf1;
}
.Tender {
  background-color: #f5c6cb;
}
.Will {
  background-color: #cce5ff;
}
.In-the-money-Derivative {
  background-color: #bee5eb;
}
.Voting-Trust {
  background-color: #e2e3e5;
}

.insider-10b5-1 .true {
  background-color: #d4edda;
}

.insider-10b5-1 .false {
  background-color: #f8d7da;
}

.insider-expand,
.insider-expand-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 0.1;
}

.insider-no-data {
  text-align: center;
  width: 100%;
  align-items: center;
  color: #8cdfd6;
  margin-top: 20px;
  margin-bottom: 20px;
}

.insider-load-more {
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 12px 16px;
}

.insider-load-more-btn {
  background-color: transparent;
  border: none;
  color: #3f3f3f;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.insider-load-more-btn.loading {
  cursor: wait;
}

/* Updated styles for the filter dropdown panel */
.dropdown-panel {
  position: absolute;
  top: 3rem;
  width: 200px;
  left: -70px;
  height: 160px;
  background-color: #ffffff;
  border-radius: 16px;
  z-index: 1000;
  overflow: hidden;
}

.filter-content {
  display: flex;
  flex-direction: column;
}

.filter-searchbar {
  padding: 8px 16px;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}

.filter-content span {
  max-height: 115px;
  overflow-y: auto;
}

.filter-content label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding: 4px 16px;
  transition: all 0.3s;
}

.filter-content label input {
  margin-right: 10px;
  width: fit-content;
}

.filter-content label:hover {
  background-color: #e8e8f9;
}

.filter-separator {
  margin: 10px 0;
  border-top: 1px solid #d1d1e9;
}

.overlay {
  display: none;
}

.filter-buttons-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.filter-button {
  background-color: #7b61ff;
  color: #ffffff;
  border: none;
  border-radius: 22px;
  padding: 10px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-button:hover {
  background-color: #6a54e3;
}

/* Scrollbar styling */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  transition: background-color 0.3s;
}

.custom-scrollbar.scrolling::-webkit-scrollbar-thumb {
  background-color: #3f3f3f;
}

.custom-scrollbar.scrolling:hover::-webkit-scrollbar-thumb {
  background-color: #3f3f3f;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.custom-scrollbar.scrolling {
  scrollbar-color: #3f3f3f transparent;
}

.custom-scrollbar.scrolling:hover {
  scrollbar-color: #3f3f3f transparent;
}
