.dashboard {
  background-color: #0d0d1e;
  width: 100vw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.content {
  display: flex;
}

@media (max-width: 767px) {
  .content {
    flex-direction: column;
    /* width:20vw; */
    /* Stack items vertically for smaller screens */
  }
}
