/* CSS to initially hide the scrollbar thumb */
.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: background-color 0.3s;
  }
  
  .custom-scrollbar.scrolling::-webkit-scrollbar-thumb {
    background-color: #8cdfd6;
  }
  
  .custom-scrollbar.scrolling:hover::-webkit-scrollbar-thumb {
    background-color: #6bbfaf; /* Change the color when hovering */
  }
  
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
  
  .custom-scrollbar.scrolling {
    scrollbar-color: #8cdfd6 transparent;
  }
  
  .custom-scrollbar.scrolling:hover {
    scrollbar-color: #6bbfaf transparent; /* Change the color when hovering */
  }
  