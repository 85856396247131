/* financialData.css */
body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.financial-data {
  background-color: #ffffff;
  height: 100dvh;
  width: 100vw;
  overflow: auto;
}

.header-container {
  display: flex;
  align-items: center;
}

.companyName {
  font-size: 2ch;
  margin-left: 10px;
}

.tooltip {
  position: absolute;
  top: 100%; /* Position tooltip below the companyName */
  left: 10vw;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 320px; /* Adjust the width as needed */
  height: 250px; /* Adjust the height as needed */
  transform: translateY(
    10px
  ); /* Add some spacing between the tooltip and the company name */
}

.companyText {
  font-size: 15px; /* Adjust font size as needed */
  margin-right: 10px; /* Add space between items */
  color: aliceblue; /* Set text color */
  padding-top: 8px; /* Adjust padding as needed */
  white-space: nowrap; /* Prevent text from wrapping */
}

.companyText a {
  color: #8cdfd6;
  text-decoration: none;
}

.green-text {
  color: #8cdfd6;
}

.red-text {
  color: red;
}

.financial-data-line {
  display: flex;
  gap: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  overflow-x: auto;
  padding-bottom: 10px;
  margin-right: 20px;
}

.financial-data-line span {
  white-space: nowrap;
}

.financial-data-line a:hover {
  text-decoration: underline;
}

.financial-data-line::-webkit-scrollbar {
  height: 5px;
  background: transparent;
}

.financial-data-line::-webkit-scrollbar-thumb {
  border-radius: 10px;
  opacity: 0.4;
  transition: opacity 0.3s;
}

.financial-data-line::-webkit-scrollbar-thumb:hover {
  background: #8cdfd6;
}

.financial-content {
  display: flex;
  /* gap: 20px; */
  overflow: hidden;
}

.fin-card {
  display: flex;
  flex-direction: column;
}

.fin-columns-container {
  display: flex;
  width: 100%;
  height: 100dvh;
  align-items: center; /* Center child elements vertically */
  justify-content: center; /* Center child elements horizontally */
}

.fin-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  margin-left: 20vw;
  margin-right: 20vw;
  margin-top: 20dvh;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .financial-content {
    flex-direction: column;
    gap: 10px;
  }

  .fin-card {
    order: 2;
    width: 100vw;
    height: auto;
    margin-bottom: -2px;
    overflow-x: auto;
  }

  .financial-card {
    background-color: #3b3aa0;
    width: auto;
    height: 7.2dvh;
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
  }

  .fin-table {
    width: 95vw;
    height: 78dvh;
    margin-left: 20px;
    overflow-x: hidden;
  }

  .table-content {
    width: 90vw;
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    overflow-x: auto;
  }

  .table-cell a {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    font-size: 10px;
  }

  .table-row .row,
  .table-row .middle-column,
  .table-row .row-last {
    background-color: transparent;
    padding: 0px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-top: 1px solid #8c8c8c;
    border-right: 1px solid #8c8c8c;
  }

  .small-search-fin {
    margin-left: 12px;
    padding-left: 10px;
    margin-bottom: 8px;
    padding-right: 5px;
  }

  .financial-card-btn {
    background-color: #8cdfd6;
    border: none;
    color: black;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    white-space: nowrap;
    flex: 0 0 auto;
  }

  .financial-card-btn.active {
    background-color: #0d0d1e;
    color: #ffffff;
    border: none;
    text-decoration: underline;
  }
  .financial-link,
  .document-link {
    border-right: 1px solid #8cdfd6 !important;
    border-bottom: none !important;
    padding: 5px 10px !important;
  }
}

.financial-link,
.document-link {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  width: 90%;
  border-bottom: 1px solid #8cdfd6;
  padding: 20px;
  cursor: pointer;
  text-decoration: none;
}
.financial-link.active {
  font-weight: 700;
}
@media (min-width: 768px) {
  .financial-content {
    /* gap: 20px; */
  }

  .financial-card {
    background-color: #3b3aa0;
    width: 13vw;
    height: 62dvh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition-duration: 0.125s;
    overflow: hidden;
    padding-top: 10px;
    margin-left: 20px;
  }

  .financial-card:hover {
    box-shadow: 0 0 10px 3px rgba(75, 75, 176, 0.8);
    transition-duration: 0.125s;
  }
  .fin-table {
    background-color: transparent;
    border-radius: 11px;
    width: calc(85vw - 20px);
    height: auto;
    max-height: 62dvh;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition-duration: 0.125s;
    padding: 0 0 10px 0;
    display: flex;
    flex-direction: column;
  }

  .table-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;
    padding-right: 10px;
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .table-cell a {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
  }

  .table-row .row,
  .table-row .middle-column,
  .table-row .row-last {
    background-color: transparent;
    padding: 0px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-top: 1px solid #8c8c8c;
    border-right: 1px solid #8c8c8c;
  }
}

.proxies-load-more {
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 0 0 4px 4px;
  background-color: #948cf2;
}

.no-data {
  text-align: center;
  width: 100%;
  align-items: center;
  color: #8cdfd6;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.searchbar-container-fin {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-input:focus {
  outline: none;
}

.back-btn-fin {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
}

#search-icon-small {
  color: #fff;
  font-size: 24px;
}

.full-width {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .small-search {
    display: none;
  }

  .small-search-fin {
    display: none;
  }
}
