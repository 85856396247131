/* src/styles/usersettings.css */

.user-settings-container {
  padding: 20px;
}

.user-title {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.content-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.content-box-1 {
  background-color: #2d2d6d;
  width: 30%; /* Adjust width as needed */
  height: 66dvh; /* Adjust height as needed */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-size: 24px;
  padding-top: 20px; /* Add padding at the top */
}

.content-box-2 {
  background-color: #2d2d6d;
  width: 68%; /* Adjust width as needed */
  height: 66dvh; /* Adjust height as needed */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  color: #ffffff;
  padding-top: 20px;
  font-size: 20px;
}

.content-box-2 span {
  margin-bottom: 20px;
}

.button-row {
  width: 80%;
  padding: 15px 20px;
  border-bottom: 1px solid #ffffff;
  text-align: center;
  cursor: pointer;
}

.button-row:last-child {
  border-bottom: none; /* Remove the border from the last child */
}

.button-row:hover {
  background-color: #1a1a4d;
}

.button-row.logout {
  width: 50%; /* Reduce the width of the logout button */
  background-color: #ff6b6b;
  color: #ffffff;
  margin-top: 40px; /* Increase margin-top to move it further down */
  border-radius: 5px;
  padding: 10px 20px; /* Add some padding for better appearance */
}
