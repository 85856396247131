@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Sora:wght@100..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Inter", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
li {
  list-style: disc;
  list-style-position: outside;
  margin-left: 16px;
}

iframe #document html {
  scroll-behavior: smooth;
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  font-feature-settings: initial;
  font-variation-settings: initial;
  font-size: inherit;
}

table {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-top: -10px;
  width: 100%;
}
tr:hover td {
  background-color: #e5e2ff;
}
th,
td {
  font-size: 12px;
}
th {
  text-align: left;
}
td {
  background-color: #f2f2f2;
  text-align: left;
  transition-duration: 300ms;
  padding: 0;
}
td:first-child {
  border-left-style: solid;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
}

.strike {
  text-decoration: line-through;
}
.prevent-highlight {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input {
  @apply text-black-smTint;
}
input:focus {
  @apply text-black-xsTint;
}

mark.green {
  background-color: #cbffcc;
}
mark.red {
  background-color: #ffd9d9;
}
