/* styles/earnings.css */

.earnings-table {
  width: 100%;
  height: 79dvh;
}

.earnings-table-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-collapse: collapse;
  font-size: 14px;
}

.earnings-table-wrapper {
  width: 100%;
}

.earnings-table-headers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.earnings-filedate-header,
.earnings-quarter-header,
.earnings-topic-header {
  flex: 1;
  text-align: left;
  font-weight: bold;
  padding: 5px 10px;
}

.earnings-filedate-header {
  flex: 1; /* Adjust the flex value to provide more space if needed */
}

.earnings-quarter-header {
  flex: 1;
}

.earnings-topic-header {
  flex: 2; /* Increase width of the topic text */
}

.earnings-table-row {
  background-color: #f2f2f2;
  margin-bottom: 10px;
  padding: 12px 16px;
  height: auto;
  border-radius: 16px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  font-size: 14px;
}
.earnings-table-row * {
  color: #3f3f3f;
}

.earnings-table-row:hover {
  background-color: #e5e2ff; /* Highlight color on hover */
}

.earnings-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.earnings-filedate,
.earnings-quarter,
.earnings-topic {
  flex: 1;
  text-align: left;
  padding: 5px 10px;
}

.earnings-topic {
  flex: 2; /* Increase width of the topic text */
}

.earnings-topic a {
  color: inherit; /* Inherit color from parent */
}

.earnings-topic a:hover {
  color: #3b3aa0; /* Change color on hover */
}

.earnings-no-data {
  text-align: center;
  width: 100%;
  align-items: center;
  color: #8cdfd6;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}
