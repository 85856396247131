/* Add or update these CSS classes */

.financial-content {
  position: relative;
  display: flex;
}

.search-bar-small {
  color: aliceblue;
  background-color: transparent;
  height: 40px;
  width: 80vw;
  border-radius: 11px;
  display: flex;
  align-items: center;
  border: 3px solid #e7e7e7;
  padding: 0 0 0 10px;
  margin-bottom: 10px;
  position: relative; /* Ensure the search bar stays in place */
  z-index: 10; /* Ensure the search bar is above the menu */
}

.searchbar-container {
  position: relative;
  display: flex;
  z-index: 10;
}

input:focus {
  outline: none;
}

#search-icon-small {
  color: rgb(255, 255, 255);
  cursor: pointer;
}

@media (max-width: 767px) {
  .full-width {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0d0d1e;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 10px 20px 0px 0;
    box-sizing: border-box;
    z-index: 1003; /* Ensure the full-width search bar is above other content */
    justify-content: space-between;
  }

  .back-btn {
    background-color: transparent;
    border: none;
    color: #e7e7e7;
    font-size: 24px;
    cursor: pointer;
    margin-right: 5px;
  }
}

@media (min-width: 768px) {
  .full-width {
    display: none; /* Hide full-width search bar on larger screens */
  }
}
