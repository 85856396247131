.search-bar {
  color: #3f3f3f;
  background-color: #f2f2f2;
  height: 48px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 10px; /* Adjusted padding to ensure proper alignment */
  position: relative; /* Ensure the search bar stays in place */
  z-index: 10; /* Ensure the search bar is above the menu */
  gap: 8px;
}

.search-input {
  background-color: transparent;
  border: none;
  color: #3f3f3f;
  height: 100%;
  padding-left: 10px;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

input {
  background-color: transparent;
  border: none;
  width: 90%; /* Adjusted width to make room for the search icon */
  height: 100%;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.search-bar-small {
  color: #3f3f3f;
  background-color: transparent;
  height: 40px;
  width: 80vw;
  border-radius: 11px;
  display: flex;
  align-items: center;
  border: 3px solid #e7e7e7;
  padding: 0 0 0 10px;
  margin-bottom: 10px;
  position: relative; /* Ensure the search bar stays in place */
  z-index: 1002; /* Ensure the search bar is above the menu */
}

.searchbar-container {
  position: relative;
  display: flex;
  z-index: 1002;
}

input:focus {
  outline: none;
}

#search-icon {
  color: white;
  margin: 0 auto;
}

#search-icon-container {
  background-color: #948cf3; /* Background color for the rectangle */
  padding: 8px;
  width: 30px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Cursor to indicate it's clickable */
  width: auto;
}

@media (max-width: 767px) {
  .full-width {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0d0d1e;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 10px 20px 0px 0;
    box-sizing: border-box;
    z-index: 1003; /* Ensure the full-width search bar is above other content */
    justify-content: space-between;
  }

  .back-btn {
    background-color: transparent;
    border: none;
    color: #e7e7e7;
    font-size: 24px;
    cursor: pointer;
    margin-right: 5px;
  }
}

@media (min-width: 768px) {
  .full-width {
    display: none; /* Hide full-width search bar on larger screens */
  }
}
