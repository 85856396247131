.news-table-wrapper {
  width: 100%;
  height: 90dvh;
}

.news-inner-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-collapse: collapse;
  font-size: 14px;
}

.news-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  margin-bottom: 10px;
}

.news-filter-container {
  display: flex;
  align-items: center;
}

.news-reset-filter-btn {
  background-color: transparent;
  border: none;
  color: #f25757;
  padding: 5px 10px;
  cursor: pointer;
  width: 60px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 4px;
}

.news-filter-wrapper {
  display: flex;
  align-items: center;
}

.news-filter-clear-visible {
  display: inline;
  padding: 0;
  background-color: transparent;
  border: none;
  color: #f25757;
  height: 12px;
  width: 12px;
  cursor: pointer;
}

.news-filter-clear-invisible {
  display: none;
}

.news-filter-selection-wrapper {
  display: flex;
}

.news-filter-selection {
  background-color: #8cdfd6;
  border: none;
  padding: 5px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.news-filter-text {
  background-color: #8cdfd6;
  border: none;
  color: black;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.news-table-row {
  background-color: #f2f2f2;
  margin-bottom: 10px;
  padding: 12px 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s;
  position: relative;
  z-index: 1; /* Lower z-index for rows */
  font-size: 14px;
}
.news-table-row * {
  color: #3f3f3f;
}

.news-table-row:hover {
  background-color: #e5e2ff; /* Highlight color on hover */
}

.news-row-formtype {
  position: relative;
  text-align: center;
  width: 150px;
  z-index: 1;
}

.news-row {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}

.news-date {
  text-align: right;
  color: #3f3f3f;
  width: 150px; /* Increase width for better spacing */
}

.news-no-data {
  text-align: center;
  width: 100%;
  align-items: center;
  color: #8cdfd6;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.news-load-more {
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 12px 16px;
}

.news-load-more-btn {
  background-color: transparent;
  border: none;
  color: #3f3f3f;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.news-load-more-btn.loading {
  cursor: wait;
}

.news-table-cell {
  height: auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  position: relative;
}

.news-row-last {
  padding-right: 10px;
  width: 150px; /* Ensure enough space for date */
  text-align: right; /* Align text to the right */
}

.news-table-row:first-child {
  border-top: none; /* Remove border-top for the first row */
}

.news-table-row .news-row-formtype:last-child,
.news-table-row .news-row:last-child,
.news-table-row .news-row-last:last-child {
  border-right: none; /* Hide right border for the last column */
}
