.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3f3f3f;
  width: 200px; /* Adjust the width as needed */
  height: 97.5dvh;
  padding: 20px 20px 0px 20px;
  overflow: hidden;
  margin-left: 8vw;
  background-color: #f2f2f2; /* Light background color */
}

.sidebar-links {
  max-height: calc(100dvh - 183px);
}

.sidebar-links button {
  width: -webkit-fill-available;
}

.company-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
  margin-top: 40px;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* In Sidebar.css */
.all-filings-button {
  width: 155px; /* Adjust the width as needed */
}

.button {
  padding: 10px 10px 10px 30px; /* Add padding to make space for the bullet points */
  margin: 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  border: none;
  background: none;
  text-align: left;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button::before {
  content: "•"; /* Bullet point character */
  color: black; /* Bullet color */
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 40px; /* Adjust size as needed */
}

.button::after {
  content: "";
  position: absolute;
  top: 0;
  left: -20px; /* Adjust to cover bullet point area */
  width: calc(100% + 20px); /* Extend the background to include bullet point */
  height: 100%;
  background-color: rgba(160, 60, 60, 0.1); /* Light gray background */
  z-index: -1;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.button.active::after {
  opacity: 1;
}

.button.active {
  color: #3f3f3f;
  background-color: #e5e2ff;
  border-radius: 8px;
}

.arrow-icon {
  font-size: 20px;
  margin-left: 10px;
}

.sub-buttons-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.sub-button {
  padding: 8px 10px 8px 30px;
  margin: 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
  background: none;
  text-align: left;
  font-size: 14px;
  position: relative;
}

.sub-button::before {
  content: "•"; /* Bullet point character */
  color: grey; /* Grey bullet color */
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 40px; /* Adjust size as needed */
}

.sub-button.active {
  color: #3f3f3f;
  background-color: #e5e2ff;
  border-radius: 8px;
}

.search-results {
  position: absolute;
  bottom: 60px;
  width: 85%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
}

.search-result {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-result:hover {
  background-color: #f0f0f0;
}

.result-icon {
  margin-right: 10px;
}

.result-label {
  font-size: 14px;
  color: #333;
}

@media (max-width: 768px) {
  .company-info-dropdown {
    /* 109 comes from 85 (headerNew width) + 12 (left margin in company info dropdown) + 12 (additional margin to the right) */
    width: calc(100vw - 109px);
  }
}
