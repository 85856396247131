/* registration.css */

.registration-table-wrapper {
  width: 100%;
}

.registration-table-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-collapse: collapse;
  font-size: 14px;
}

.registration-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  margin-bottom: 10px;
}

.registration-filter-container {
  display: flex;
  align-items: center;
}

.registration-reset-filter-btn {
  background-color: transparent;
  border: none;
  color: #f25757;
  padding: 5px 10px;
  cursor: pointer;
  width: 60px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 4px;
}

.registration-filter-wrapper {
  display: flex;
  align-items: center;
}

.registration-filter-selection-wrapper {
  display: flex;
}

.registration-filter-clear-visible {
  display: inline;
  padding: 0;
  background-color: transparent;
  border: none;
  color: #f25757;
  height: 12px;
  width: 12px;
  cursor: pointer;
}

.registration-filter-clear-invisible {
  display: none;
}

.registration-filter-selection {
  background-color: #8cdfd6;
  border: none;
  padding: 5px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.registration-filter-text {
  background-color: #8cdfd6;
  border: none;
  color: black;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.registration-table-row {
  background-color: #f2f2f2;
  margin-bottom: 10px;
  padding: 12px 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  font-size: 14px;
}
.registration-table-row * {
  color: #3f3f3f;
}
.registration-table-row:hover {
  background-color: #e5e2ff; /* Highlight color on hover */
}

.registration-row-formtype {
  flex: 0 0 150px;
  position: relative;
  text-align: center;
  z-index: 1;
}

.registration-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.registration-quarter {
  flex: 1 0 auto;
  font-weight: bold;
  text-align: left; /* Align text to the left */
  padding-left: 10px; /* Adjust the padding to fine-tune the position */
}

.registration-date {
  flex: 0 0 150px; /* Increase width for better spacing */
  text-align: right;
  color: #3f3f3f;
  font-weight: bold;
  padding-right: 10px; /* Adjust the padding to fine-tune the position */
}

.registration-row a {
  color: #3f3f3f; /* Default link color */
  text-decoration: none;
  font-size: 15px;
  display: inline-block;
  max-width: 700px; /* Set the maximum width for the text */
  white-space: nowrap; /* Prevent the text from wrapping */
  overflow: hidden; /* Hide any overflow text */
  text-overflow: ellipsis; /* Show ellipsis (...) for overflow text */
}

.registration-row a:hover {
  color: #3f3f3f; /* Link color on hover */
}

.registration-no-data {
  text-align: center;
  width: 100%;
  align-items: center;
  color: #8cdfd6;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.registration-load-more {
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 12px 16px;
}

.registration-load-more-btn {
  background-color: transparent;
  border: none;
  color: #3f3f3f;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.registration-load-more-btn.loading {
  cursor: wait;
}

.registration-title:hover::after {
  content: attr(title);
  white-space: normal;
  position: absolute;
  background-color: white;
  color: black;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
  max-width: 300px; /* Adjust as necessary */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  top: 100%; /* Position below the title */
  left: 50%;
  transform: translate(-50%, 10px); /* Center align and add some space */
}

.registration-title {
  display: inline-block;
  max-width: 700px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.registration-row-last {
  padding-right: 10px;
}
