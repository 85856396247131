.header-container {
  background-color: #f2f2f2;
  display: flex;
  box-shadow: 2px 10px 10px 0px rgba(0, 0, 0, 0.5); /* h-offset v-offset blur */

  flex-direction: column; /* Align children in a column */
  align-items: center; /* Center align horizontally */
  height: 100dvh;
  width: 8vw;
  position: fixed;
  left: 0; /* Ensure the container sticks to the left */
  top: 0; /* Start from the top */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
}

.logo-container {
  height: 100px;
  margin-top: 40px; /* Add some margin if needed */
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 20px; /* Space between the elements */
}

.icon-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px; /* Add margin between the buttons */
}

.icon-button.active,
.circle-container.active {
  background-color: #e5e2ff;
  border-radius: 30px;
  width: 60px;
}

.icon {
  font-size: 40px; /* Adjust the icon size */
  margin-bottom: 5px; /* Space between the icon and the text */
  color: #3f3f3f;
}

.text {
  font-size: 14px; /* Adjust the text size */
  color: #3f3f3f;
}

.spacer {
  flex-grow: 1; /* Takes up remaining space */
}

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #3f3f3f;
  color: white;
  border-radius: 50%;
  margin-bottom: 20px; /* Add margin at the bottom */
  font-size: 14px; /* Adjust the text size */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.circle-container.active {
  background-color: #e5e2ff;
  color: #3f3f3f;
  border-radius: 50%; /* Maintain circle shape */
  width: 60px;
  height: 60px;
}
