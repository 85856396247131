.loading-bar {
  --uib-color: #948cf2;
  --uib-speed: 1.75s;
  --uib-stroke: 5px;
  --uib-bg-opacity: 0.1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--uib-stroke);
  width: calc(100% - 24px);
  border-radius: calc(var(--uib-stroke) / 2);
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  margin: auto;
}

.loading-bar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--uib-color);
  opacity: var(--uib-bg-opacity);
  transition: background-color 0.3s ease;
}

.loading-bar::after {
  content: "";
  height: 100%;
  width: 100%;
  border-radius: calc(var(--uib-stroke) / 2);
  animation: wobble var(--uib-speed) ease-in-out infinite;
  transform: translateX(-95%);
  background-color: var(--uib-color);
  transition: background-color 0.3s ease;
}

@keyframes wobble {
  0%,
  100% {
    transform: translateX(-95%);
  }
  50% {
    transform: translateX(95%);
  }
}
