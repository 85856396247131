.all-financials-container {
  width: 100%;
  height: 79dvh;
}

.all-financials-table-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-collapse: collapse;
  font-size: 14px;
}

.all-financials-table-wrapper {
  width: 100%;
}

.all-financials-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.all-financials-title {
  font-size: 18px;
  color: black;
  margin-right: auto;
}

.all-financials-filter-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.all-financials-reset-filter-btn {
  background-color: transparent;
  border: none;
  color: #f25757;
  padding: 5px 10px;
  cursor: pointer;
  width: 60px;
  font-size: 15px;
  border-radius: 4px;
}

.all-financials-filter-dropdown-wrapper {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Add space between buttons and dropdowns */
}

.all-financials-filter-selection-wrapper {
  display: flex;
  margin-right: 10px; /* Add space between buttons and dropdowns */
}

.all-financials-filter-clear-visible {
  display: inline;
  padding: 0;
  background-color: transparent;
  border: none;
  color: #f25757;
  height: 12px;
  width: 12px;
  cursor: pointer;
}

.all-financials-filter-clear-invisible {
  display: none;
}

.all-financials-filter-selection {
  background-color: #8cdfd6;
  border: none;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.all-financials-filter-text {
  background-color: #8cdfd6;
  border: none;
  color: black;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.all-financials-table-row {
  background-color: #f2f2f2;
  margin-bottom: 10px;
  padding: 12px 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  font-size: 14px;
}

.all-financials-table-row * {
  color: #3f3f3f;
}

.all-financials-table-row:hover {
  background-color: #e5e2ff; /* Highlight color on hover */
}

.all-financials-row-formtype {
  position: relative;
  text-align: center;
  width: 150px;
  z-index: 1;
  margin-right: 10px; /* Add space between form type and other elements */
}

.all-financials-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}

.all-financials-quarter {
  margin-right: 10px;
  text-align: left;
  width: 500px; /* Adjust the width for better alignment */
  max-width: 600px; /* Ensure the width is limited */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis */
  white-space: nowrap; /* Prevent text from wrapping */
  padding-left: 20px;
}

.all-financials-date {
  text-align: right;
  margin-left: auto; /* Pushes the date to the right end */
  width: 150px; /* Adjust the width as needed */
}

.all-financials-no-data {
  text-align: center;
  width: 100%;
  align-items: center;
  color: #8cdfd6;
  margin-top: 20px;
  margin-bottom: 20px;
}

.all-financials-load-more {
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 12px 16px;
}

.all-financials-load-more-btn {
  background-color: transparent;
  border: none;
  color: #3f3f3f;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.all-financials-load-more-btn.loading {
  cursor: wait;
}
