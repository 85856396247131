/* styles/login.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  background-color: #ffffff;
}

.login-bg {
  background-color: #ffffff;
  border-radius: 24px;
  margin: 24px;
  @apply drop-shadow-subtle-soft;
}
