.search-results-sidebar {
  @apply flex flex-col items-center text-black-xsTint w-[215px] h-full bg-white-xsShade drop-shadow-navigation-bar relative z-20 transition-all duration-300;
}

.search-results-container {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  height: 100%;
  overflow-y: auto;
}

.search-results-container * {
  text-align: left;
}

.search-results-container li {
  list-style: none;
}

.search-result-instance::after,
.search-result-instance::before {
  content: '"';
}
