.loading-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80dvh;
}

.loading-page-loader {
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading-page-circle {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #8cdfd6;
  animation: loading-page-move 0.6s infinite alternate;
}

.loading-page-circle:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-page-circle:nth-child(3) {
  animation-delay: 0.4s;
}

.loading-page-shadow {
  width: 20px;
  height: 4px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: rgba(140, 223, 214, 0.6);
  position: absolute;
  bottom: -10px;
  animation: loading-page-shadow 0.6s infinite alternate;
}

.loading-page-shadow:nth-child(5) {
  animation-delay: 0.2s;
}

.loading-page-shadow:nth-child(6) {
  animation-delay: 0.4s;
}

.loading-page-text {
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5em;
  letter-spacing: 2px;
}

@keyframes loading-page-move {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes loading-page-shadow {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1.5);
  }
}
